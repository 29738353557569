<template>
  <div class="vx-col">
    <span>{{title}}</span>
    <div class="flex">
      <div class="w-1/5 bg-grid-color h-12">Name</div>
      <div class="w-4/5 bg-grid-color-secondary h-12">
        : <b>{{this.data.name}}</b>
      </div>
    </div>
    <list :url="url"></list>
  </div>
</template>

<script>
import List from "./List";
export default {
  components: {
    List
  },
  props: {
    data: {
      type: Object
    },
    title: {
      type: String
    },
    action: {
      type: String
    },
    url: {
      type: String
    }
  }
};
</script>